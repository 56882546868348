<template>
    <div class="mt-10">
        <v-expand-transition>
            <v-progress-linear v-if="oauthLoading || loading" color="primary" indeterminate></v-progress-linear>
        </v-expand-transition>
        <div :class="['d-flex mt-2 flex-wrap', {'scale-up': !$vuetify.breakpoint.xs}]" ref="parent">
            <v-btn
                color="primary"
                @click="$emit('click')"
                large
                outlined
                elevation="0"
                :loading="loading"
                :width="$vuetify.breakpoint.xs? '100%': '200px'"
                height="40"
                :disabled="loading || oauthLoading"
            >
                {{ manualAuthButtonText }}
            </v-btn>
    
            <div :class="['divider align-center justify-center mx-3', {'flex-grow-1 my-4': $vuetify.breakpoint.xs}]">
                <v-divider v-if="$vuetify.breakpoint.xs" class="divider-item"></v-divider>
                <span class="text-body-2">or</span>
                <v-divider v-if="$vuetify.breakpoint.xs" class="divider-item"></v-divider>
            </div>
            <div :class="{'disable-click': oauthLoading}">
                <div id="g_id_onload"
                    :data-client_id="clientId"
                    data-context="signin"
                    data-ux_mode="popup"
                    :data-login_uri="loginEndpoint"
                    data-callback="handleCredentialResponse"
                >
                </div>
                <div v-if="parentWidth" class="g_id_signin"
                    data-type="standard"
                    data-size="large"
                    data-theme="filled_blue"
                    data-text="continue_with"
                    data-shape="rectangular"
                    data-logo_alignment="left"
                    data-height="50"
                    :data-width="$vuetify.breakpoint.xs ? parentWidth : '200'"
                >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { mapMutations } from 'vuex';
export default {
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        manualAuthButtonText: {
            type: String,
            default: 'Log in'
        }
    },
    computed:{
        clientId(){
            return process.env.VUE_APP_GOOGLE_CLIENT_ID
        },
        loginEndpoint(){
            return window.location.origin + this.$route.fullPath;
        }
    },
    data() {
        return {
            parentWidth: 0,
            oauthLoading: false,
        };
    },
    methods: {
        ...mapMutations(['showSnackBar']),
        async handleGoogleOAuthResponse(response){
            try {
                this.oauthLoading = true;
                
                const res = await axios.post(`/api/continue-with-google${window.location.search}`, {
                    ...response
                })


                this.oauthLoading = false;
                if(res.data){
                    
                    this.$auth.token(null, res.headers.authorization, false);
                    this.$auth.user(res.data.user);
                    if(res.data.action === 'register'){
                        _cio && _cio.identify({
                            ...this.$auth.user()
                        })

                        _cio && _cio.track('sign_up.success')

                        this.$gtag.event('sign_up');
                        
                        if(this.$route.query.trial==='true'){
                            this.$gtag.event('free_trial_start');
                            this.$gtag.event('free_trial_start', {
                                'event_label': 'free_trial',
                                'non_interaction': true
                            });
                        }
                        return this.$router.push({ name: "user-info" });
                    }
                    this.$router.push(this.$auth.options.loginData.redirect.other);
                }
                
            } catch (error) {
                console.error(error)
                this.oauthLoading = false
                this.showSnackBar({
                    text: 'Data invalid. Please check and try again.', 
                    color: 'error', 
                    timeout: 2000
                })
            }
        }
    },
    mounted() {

        this.parentWidth = this.$refs.parent.offsetWidth;

        let script = document.createElement('script');
        script.src = 'https://accounts.google.com/gsi/client';
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);

        // Handle Google Sign In
        const vm = this;
        window.handleCredentialResponse = function(response) {
            vm.handleGoogleOAuthResponse(response);
        };
    },
    beforeDestroy() {
        // Cleanup to avoid memory leaks
        delete window.handleCredentialResponse;
    },
}
</script>

<style lang="scss" scoped>
.scale-up{
    transform: scale(1.1);
    transform-origin: top left;
}
.divider {
    display: flex;
    align-items: center;

    &-label {
        margin-left: 8px;
        margin-right: 8px;
    }
}
.disable-click{
    pointer-events: none;
}
</style>