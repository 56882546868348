import { render, staticRenderFns } from "./AuthenticationButtons.vue?vue&type=template&id=20846db6&scoped=true&"
import script from "./AuthenticationButtons.vue?vue&type=script&lang=js&"
export * from "./AuthenticationButtons.vue?vue&type=script&lang=js&"
import style0 from "./AuthenticationButtons.vue?vue&type=style&index=0&id=20846db6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20846db6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
installComponents(component, {VBtn,VDivider,VExpandTransition,VProgressLinear})
